import React from 'react';
import logo from './assets/logo.png';
import './App.css';

function App() {
  return (    
    <div id="app">
      <img src={logo} alt="InfoInsight" />     
    </div>    
  );
}

export default App;
